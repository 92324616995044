<template>
	<div class="mainlist">
		<el-form class="mainContent" label-width="0">
			<el-form-item style="margin-bottom:0;"><div class="question-title" style="margin-top: 10px;">就诊记录</div></el-form-item>
			<el-form-item style="margin-bottom:0;"><div class="firsttitle">就诊信息</div></el-form-item>
			<el-form-item>
				<el-row class="questionmain">
					<el-col :span="12">就诊日期</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.visitDate }}</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">就诊医院</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.hospitalName }}</el-col>
				</el-row>
			</el-form-item>
			<el-form-item style="margin-bottom:0;"><div class="firsttitle">化验结果单</div></el-form-item>
			<el-form-item>
				<el-row class="questionmain">
					<el-col :span="12">糖化血红蛋白</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult1 }} mmol/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">甘油三酯</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult2 }} mmol/L</el-col>
				</el-row>
			</el-form-item>
			<el-form-item style="margin-bottom:0;"><div class="firsttitle">血糖</div></el-form-item>
			<el-form-item>
				<el-row class="questionmain">
					<el-col :span="12">空腹</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult3 }} mmol/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后1h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult4 }} mmol/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后2h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult5 }} mmol/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后3h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult6 }} mmol/L</el-col>
				</el-row>
			</el-form-item>
			<el-form-item style="margin-bottom:0;"><div class="firsttitle">C肽</div></el-form-item>
			<el-form-item>
				<el-row class="questionmain">
					<el-col :span="12">空腹</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult7 }} pmol/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后1h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult8 }} pmol/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后2h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult9 }} pmol/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后3h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult10 }} pmol/L</el-col>
				</el-row>
			</el-form-item>
			<el-form-item style="margin-bottom:0;"><div class="firsttitle">胰岛素</div></el-form-item>
			<el-form-item>
				<el-row class="questionmain">
					<el-col :span="12">空腹</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult11 }} mU/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后1h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult12 }} mU/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后2h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult13 }} mU/L</el-col>
				</el-row>
				<el-row class="questionmain">
					<el-col :span="12">餐后3h</el-col>
					<el-col :span="12">{{ gnrhMedicalForm.testReportResult14 }} mU/L</el-col>
				</el-row>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
export default {
	name: 'GnrhMedicalDetail',
	data() {
		return {
			gnrhMedicalForm: {},
			token: '',
			uuid: ''
		};
	},
	mounted() {
		this.token = this.$route.query.token;
		this.uuid = this.$route.query.uuid;
		console.log(this.uuid);
		this.getGnrhMedicalRecord();
	},
	methods: {
		getGnrhMedicalRecord() {
			let that = this;
			that.$axios({
				method: 'get',
				url: '/api/api/patientCsiiMedicalRecord/detail/' + that.uuid
			}).then(res => {
				if (res.data.code === 200) {
					that.gnrhMedicalForm = res.data.data;
				} else {
					that.$message({
						message: res.data.message,
						type: 'error'
					});
				}
			});
		}
	}
};
</script>

<style scoped>
.mainlist {
	padding: 0;
}
.mainContent {
	overflow-x: hidden;
	background-color: #fff;
	border-radius: 10px;
	padding: 0 10px 1.25rem 10px;
}
.question-title {
	height: 3.125rem;
	line-height: 3.125rem;
	margin: 0;
	text-align: center;
	font-size: 1.375rem;
}
.firsttitle {
	font-size: 1rem;
	padding: 0 10px;
	height: 2.875rem;
	line-height: 2.875rem;
	border-bottom: 1px dashed #ddd;
	border-top: 1px dashed #ddd;
	background-color: #f8f9fc;
}
.questionmain {
	font-size: 0.875rem;
	margin: 0.625rem;
	border-bottom: 1px dashed #ddd;
}
</style>
